import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import { AuthGuard } from './_helpers';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { InitiateOnboardingComponent } from './home/initiate-onboarding/initiate-onboarding.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { ReferenceLinksComponent } from './home/reference-links/reference-links.component';
import { WorkItemComponent } from './work-item/work-item.component';
import { FaqComponent } from './faq/faq.component';
import { ReportsComponent } from './reports/reports.component';
import { TabDashboardComponent } from './tab-dashboard/tab-dashboard.component';
//import { CategoryComponent } from './category';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: InitiateOnboardingComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: TabDashboardComponent, canActivate: [AuthGuard]},
    { path: 'reference-links', component: ReferenceLinksComponent,canActivate: [AuthGuard] },
    { path: 'initiate-onboarding', component: InitiateOnboardingComponent, canActivate: [AuthGuard] },
    { path: 'work-item', component: WorkItemComponent, canActivate: [AuthGuard] },
    // login to dev
    // { path: 'login', component: LoginComponent },
    
    { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
    { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
    {path: 'faq', component: FaqComponent, canActivate: [AuthGuard]},
    
    // login to prod

    { path: 'login', component: SsoLoginComponent },
    // otherwise redirect to home
    { path: '**', redirectTo: 'home' }
    
];

export const appRoutingModule = RouterModule.forRoot(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
