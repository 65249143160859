// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  archive: false,

  //PayPal DEV COGNITO Environment
  // cognitoSignInUrl: 'https://paypalonboarding-docuedgeappsdev.auth.us-east-1.amazoncognito.com/login?client_id=4n6np8eseofj4lub1clk71hrc6&response_type=token&scope=aws.cognito.signin.user.admin&redirect_uri=https://paypalonboarding.docuedgeappsdev.deloitte.com/login',
  // cognitoSignOutUrl: 'https://paypalonboarding-docuedgeappsdev.auth.us-east-1.amazoncognito.com/logout?client_id=4n6np8eseofj4lub1clk71hrc6&response_type=token&scope=aws.cognito.signin.user.admin&redirect_uri=https://paypalonboarding.docuedgeappsdev.deloitte.com/login',


  //PayPal PROD COGNITO Environment
   cognitoSignInUrl: 'https://paypalonboarding-docuedgeapps.auth.us-east-1.amazoncognito.com/login?client_id=5g0j01oati8cfav4re1tkuirk4&response_type=token&scope=aws.cognito.signin.user.admin&redirect_uri=https://paypalonboarding.docuedgeapps.deloitte.com/login',
  cognitoSignOutUrl: 'https://paypalonboarding-docuedgeapps.auth.us-east-1.amazoncognito.com/logout?client_id=5g0j01oati8cfav4re1tkuirk4&response_type=token&scope=aws.cognito.signin.user.admin&redirect_uri=https://paypalonboarding.docuedgeapps.deloitte.com/login',

  //AWS PayPal Dev Environment
  //  apiUrl: 'https://paypalonboarding.docuedgeapidev.deloitte.com',
  //   formUrl: 'https://paypalonboarding.docuedgeapidev.deloitte.com'

    //AWS PayPal PROD Environment
    apiUrl: 'https://paypalonboarding.docuedgeapi.deloitte.com',
    formUrl: 'https://paypalonboarding.docuedgeapi.deloitte.com' 

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
